@font-face {
  font-family: "helvetica-neue-bold";
  src: url("../../Assets/Fonts/Helvetica\ Neu\ Bold.ttf") format("truetype");
}

@font-face {
  font-family: "circlular-std-book";
  src: url("../../Assets/Fonts/CircularStd-Book.ttf") format("truetype");
}

@font-face {
  font-family: "circlular-std-bold";
  src: url("../../Assets/Fonts/CircularStd-Bold.otf") format("opentype");
}

@font-face {
  font-family: "sf-pro";
  src: url("../../Assets/Fonts/SF-Pro-Text-Thin.otf") format("opentype");
}

.navbar-link {
  font-family: "circlular-std-book" !important;
  font-style: normal;
  font-size: 20px;
}

.navbar-link:hover {
  font-family: "circlular-std-book" !important;
  font-style: normal;
  font-size: 20px;
}

.dark-mode-bg {
  background-color: #58668b;
}
.dark-mode-navlink {
  color: #ffffff !important;
  transition: all 0.15s ease 0s;
}
.dark-mode-navlink:hover {
  color: #bdbdbd !important;
}

.logo {
  width: 75%;
}
