@font-face {
  font-family: "circular-std-book";
  src: url("../../Assets/Fonts/CircularStd-Book.ttf") format("truetype");
}

@font-face {
  font-family: "circular-std-med";
  src: url("../../Assets/Fonts/CircularStd-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "circular-std-bold";
  src: url("../../Assets/Fonts/CircularStd-Bold.otf") format("opentype");
}

#about-page {
  padding-left: 10%;
  padding-right: 10%;
}

#about-title {
  font-family: "circular-std-bold";
  font-size: 50px;
  margin-top: 2%;
  color: #58668b;
}

#about-main {
  font-family: "circular-std-book";
  font-size: 18px;
  padding-bottom: 3%;
  margin-bottom: 3%;
}

#about-profile-pic {
  width: 80%;
}

.paragraph {
  margin-top: 3%;
  margin-bottom: 3%;
}
