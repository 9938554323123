@font-face {
  font-family: "circular-std-book";
  src: url("../../Assets/Fonts/CircularStd-Book.ttf") format("truetype");
}

@font-face {
  font-family: "circular-std-med";
  src: url("../../Assets/Fonts/CircularStd-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "circular-std-bold";
  src: url("../../Assets/Fonts/CircularStd-Bold.otf") format("opentype");
}

.profile-pic {
  width: 90%;
}

#main-page {
  background-color: #ebf4f6;
  padding-top: 10%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 6%;
  height: 99vh;
}

#profile-circle {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #76b4bd;
  padding-top: 100%;
  text-align: center;
}

.title-text-1 {
  font-family: "circular-std-bold";
  font-size: 70px;
  color: #58668b;
  margin-left: 5%;
  padding-left: 5%;
}

.body-text-1 {
  font-family: "circular-std-book";
  font-size: 18px;
  color: #5e5656;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
  padding-left: 5%;
}

.scroll-button {
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 2%;
  margin-left: 2%;
}

.button-dark-blue {
  width: 213px;
  height: 56px;
  border: 4px solid #58668b;
  box-sizing: border-box;
  border-radius: 50px;
  text-align: center;
  font-family: "circular-std-bold";
  font-size: 25px;
  color: #58668b !important;
  padding-block-start: 2%;
  transition: box-shadow 0.3s;
}

.button-dark-blue:hover {
  box-shadow: 0 0 15px rgba(33, 33, 33, 0.2);
  cursor: pointer;
}

.button-light-blue {
  width: 213px;
  height: 56px;
  border-radius: 50px;
  background: #58668b;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 2%;
  margin-left: 2%;
  text-align: center;
  font-family: "circular-std-bold";
  font-size: 25px;
  color: #ffffff;
  padding-block-start: 1%;
  transition: box-shadow 0.3s;
}

.button-light-blue:hover {
  box-shadow: 0 0 15px rgba(33, 33, 33, 0.3);
}

.resume-link {
  color: #ffffff !important;
}

.resume-link:hover {
  text-decoration: none;
  color: #ffffff !important;
}

#skills-brief {
  background-color: #ffffff;
  padding-top: 6%;
  padding-bottom: 6%;
}

.title-text-2 {
  font-family: "circular-std-med";
  font-size: 60px;
  color: #58668b;
  margin-left: 5%;
  margin-bottom: 5%;
}

.title-text-3 {
  font-family: "circular-std-med";
  font-size: 30px;
  color: #58668b;
  margin-left: 5%;
  margin-bottom: 5%;
}

.title-text-4 {
  font-family: "circular-std-med";
  font-size: 45px;
  color: #58668b;
}

.title-text-4-white {
  font-family: "circular-std-med";
  font-size: 45px;
  color: #ffffff;
}

#skills-tools-box {
  border: 7px solid #76b4bd;
  box-sizing: border-box;
  border-radius: 30px;
  width: 120%;

  padding-left: 3%;
  padding-right: 3%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.skills-language-parent {
  padding-top: 6%;
  padding-bottom: 6%;
}

#skills-col-2 {
  padding-top: 10%;
}

.skills-icon {
  width: 35%;
  padding-right: 3%;
}

.skills-icon-name {
  font-family: "circular-std-bold";
  font-size: 28px;
  padding: 7% 0;
}

#projects-brief {
  background-color: #ebf4f6;
  padding-top: 6%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 6%;
}

#email-link {
  font-family: "helvetica-neue-bold" !important;
  font-style: normal;
  color: #82b7dc !important;
}

.text-box-projects {
  width: 100%;
}

.project-brief-section {
  padding-top: 2%;
  padding-bottom: 2%;
  text-align: center;
}

.project-brief-box {
  width: 100%;
  background: #58668b;
  border-radius: 20px;

  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;

  display: inline-block;
  vertical-align: middle;
}

.project-brief-text {
  font-family: "circular-std-book";
  color: #ffffff;

  padding: 1% 0;
}

.project-brief-title {
  font-family: "circular-std-med";
  width: 20%;
  font-size: 27px;
}

.project-brief-body {
  width: 50%;
  font-size: 16px;
  text-align: left;
}

.align-right {
  width: 30%;
  text-align: end;
}

.project-link {
  font-family: "circular-std-book" !important;
  font-size: 18px;
  color: #ffffff !important;
  transition: all 0.15s ease 0s;
}

.project-link:hover {
  font-family: "circular-std-book" !important;
  font-style: normal;
  font-size: 18px;
  color: #bfcdd6 !important;
}

#contact-info {
  background-color: #76b4bd;
  padding-top: 6%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 6%;
}

#contact-info-bar {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 45%;
}

.home-handle-icon {
  width: 55px;
  transition: all 0.1s ease 0s;
}

.home-handle-icon:hover {
  width: 60px;
}
