#handles {
  text-align: center;
  background-color: #76b4bd;
  padding-top: 1%;
  padding-bottom: 1%;
}

.social-media-bar {
  width: 40%;
  padding: 1% 0;
}

.icon-center {
  vertical-align: middle;
}

.handle-icon {
  width: 45px;
  transition: all 0.2s ease 0s;
}

.handle-icon:hover {
  width: 50px;
}
